Grid {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}



  .file-input {
    display: none;
  } 

  .drop-container-dark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 115%;
    height: 120px;
    border: 2px dashed #1ddecb;
    background-color: rgba(29, 222, 203, 0.1);
    border-radius: 1%;
    cursor: pointer;
    position: relative;
  }

  .drop-container-dark-on-drag-over {
    background-color: rgba(29, 222, 203, 0.377);
    border: 1px dashed rgb(10, 199, 25);
    border-radius: 1%;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 115%;
    height: 210px;
  }

  .drop-container-normal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 55%;
    height: 210px;
    border: 2px solid #424242;
    background-color: rgba(75, 78, 77, 0.1);
    border-radius: 1%;
    cursor: pointer;
  }

  .drop-message-dark {
    text-align: center;
    color: #1ddecb;
    font-family: Arial;
    font-size: 10px;
    white-space: normal;
    padding: 4vh;

  }
  .drop-message-normal {
    text-align: center;
    color: #424242;
    font-family: Arial;
    font-size: 20px;
    white-space: normal;
    padding: 5px;
  }

  .upload-icon {
    width: 30px;
    height: 30px;
    /* background: url(../../Media/Images/upload.png) no-repeat center center;  */
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 20px;
  }