.cursuriBox {
    padding: 24px;
}

.tableCrudForContainerControl {
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.tableCrudForContainerControl .tcfcc-header {
    background: #303030;
    border-bottom: 1px solid #545454;
    padding: 16px;
    font-weight: bold;
}

.tableCrudForContainerControl .tcfcc-body .tcfcc-body-row {
    border-bottom: 1px solid #545454;
    padding: 8px 16px;
}

.tableCrudForContainerControl .tcfcc-body .tcfcc-body-row:last-child {
    border: 0;
    padding: 16px;
}

.tableCrudForContainerControl .tcfcc-header,
.tableCrudForContainerControl .tcfcc-body .tcfcc-body-row {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.tableCrudForContainerControl .tcfcc-header .tcfcc-cell:nth-child(1),
.tableCrudForContainerControl .tcfcc-body .tcfcc-body-row .tcfcc-cell:nth-child(1) {
    width: calc(100% - 160px);
}

.tableCrudForContainerControl .tcfcc-header .tcfcc-cell:nth-child(2),
.tableCrudForContainerControl .tcfcc-body .tcfcc-body-row .tcfcc-cell:nth-child(2) {
    width: 180px;
    justify-content: center;
}

.tableCrudForContainerControl .tcfcc-cell {
    display: flex;
    align-items: center;
}

.tableCrudForContainerControl .tcfcc-cell:nth-child(1)>* {
    width: 100%;
}

.cursPageContent * {
    padding: revert;
    margin: revert;
}

.darkMode .cursPageContent span {
    filter: invert(1);
}

.mainContentCurs {
    padding: 12px;
}

.cursPageContent ul,
.cursPageContent ol {
    padding: 0 0 0 40px;
}

.progressBarCurs {
    width: 200px;
    border: 1px solid rgb(0, 255, 200);
    padding: 2px;
    border-radius: 4px;
    display: flex;
    position: relative;
    height: 20px;
    background: #606060;
}

.progressBarCurs div {
    background: rgb(0, 255, 200);
}

.progressBarCurs span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
}

.containerForControl {
    border-radius: 4px;
    overflow-x: auto;
    background: #363636;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.containerForControl .bodyForControl {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
}

.containerForControl .headerForControl {
    border-bottom: 1px solid #545454;
    background: #303030;
    padding: 12px 24px;
}

.paginiCurs ol {
    padding: 0 40px;
}

.paginiCurs .buttonPagina {
    position: relative;
}

.lightMode .adaugaPagina {
    border: 2px dashed #d0d0d0;
}

.darkMode .adaugaPagina {
    border: 2px dashed #545454;
}

.adaugaPagina {
    padding: 6px 12px;
}


.boxAdaugaPagina {
    display: flex;
    gap: 12px;
    flex-direction: column;
    margin-bottom: 12px;
}

.paginiCurs ol .buttonPagina .buttonPaginaAction {
    position: absolute;
    top: 0;
}

.box-pagini-curs {
    overflow-y: scroll;
    padding: 12px;
    margin-right: -14px;
    flex-grow: 1;
}

.box-pagini-curs>.listaPagini {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 12px;
}

.darkMode .box-pagini-curs>.listaPagini {
    background: #363636;
    border: 1px solid #545454;
}

.lightMode .box-pagini-curs>.listaPagini {
    background: #fff;
    border: 1px solid #d0d0d0;
}

.box-pagini-curs>.listaPagini:last-child {
    margin-bottom: 0;
}

.darkMode .listaPagini .buttonPagina.active {
    background: #222;
}

.lightMode .listaPagini .buttonPagina.active {
    background: #0a1b35;
    color: #fff;
}

.deletePage:hover svg {
    fill: #f88;
}

.buttonPaginaAction {
    display: block;
    padding: 12px 12px 6px 12px;
    cursor: pointer;
    z-index: 2;
    position: inherit;
    gap: 6px;
    display: flex;
    flex-direction: column;
}

.darkMode .buttonExpanderPagini {
    background: #222;
}

.lightMode .buttonExpanderPagini {
    background: #0a1b35;
    color: #fff;
}

.buttonExpanderPagini {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;
}

.progressLoader {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
}

.progressLoader>span {
    display: block;
    background: rgba(23, 123, 42, .3);
}

.paginaInfoTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paginaInfoTabIconTime {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paginaInfoTabIconTime>span {
    margin-right: 12px;
}

.isMobile .box-pagini-curs>.listaPagini:first-child {
    margin-top: 62px;
}

.isMobile .cursuri-dialog .MuiPaper-root {
    max-width: 100%;
    width: 100%;
    margin: 0;
    max-height: 100%;
}

.edit-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.save-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}


.edit-delete-icons {
    display: flex;
    align-items: center;
}

.edit-delete-icons:hover {
    color: #f88;
    transition: .5s;
}

.icon-button-hover:hover {
    background-color: rgba(255, 255, 255, 0.1);
}