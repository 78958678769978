.container-modal-surveys {
  padding: 24px;
  max-width: 900px;
}

.header-modal-surveys {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.header-title-desc {
  display: flex;
  flex-direction: column;
}
.questions-modal-surveys {
  margin-top: 48px;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.questionAndType {
  gap: 24px;
  display: flex;
}

.questionAndType input {
  min-width: 500px;
}

.questionType input {
  min-width: 200px;
}

.questions-modal-surveys h3 {
  margin-bottom: 24px;
}

.choice-modal-surveys {
  margin-bottom: 12px;
}

.choice-modal-surveys label {
  border: 1px solid grey;
  padding: 12px 24px;
  border-radius: 4px;
  display: flex;
  gap: 12px;
  cursor: pointer;
}

.justification-modal-surveys input {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  width: 100%;
}

.justification-modal-surveys {
  display: flex;
}

.buttons-modal-surveys {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
}

.questionsBox {
  background-color: #74727242;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  padding: 5px;
  border-radius: 12px;
}

.questionsBox .choices {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.inputChoices {
  max-width: 200px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
}

.divider {
  border-top: 3px dashed #b7b3b379;
  margin-top: 24px;
}

.choicesForm {
  margin: 10px;
}
.MuiButtonBase-root:disabled {
  cursor: not !important;
  pointer-events: all !important;
}
.darkMode .disabled-button {
  background-color: rgba(154, 149, 149, 0.149) !important;
  color: white !important;
}

.disabled-justification {
  background-color: rgba(255, 253, 253, 0.278);
}

.surveyTitleTable {
  cursor: pointer;
  font-weight: bold !important;
  text-decoration: underline;
}
.totalUsersResponse {
  font-weight: bold;
}
.surveyDescriptionTable {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 400px;
}

.surveyVotes {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.darkMode .surveyVotes > span {
  background: #303030;
}
.surveyVotes > span {
  background: #33333314;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}
.surveyVotes > span:hover {
  font-size: 14px;
}

.surveyVotes > span.activeVote {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
}
.createSurvey {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.createSurvey h1 {
  margin: 10px;
}
.visibilitySwitch {
  display: flex;
  flex-direction: column;
  position: relative;
}
.surveyDetails {
  gap: 20px;
  display: flex;
}

.questionForm {
  display: flex;
  gap: 10px;
}
.choiceForm {
  display: flex;
}

.zones-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.zone {
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  min-width: 200px;
}

.dragging-over {
  background-color: #a0a0a0;
}

.email-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #fff;
}
.darkMode .email-item {
  background-color: #302e2e;
}
.dragging {
  background-color: #e0e0e0;
}
.emailDNDModal {
  padding: 24px;
}
.emailDNDModal .MuiDialog-paper {
  max-width: 1280px !important;
}
.sendEmailButtons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.zone h2 {
  margin-bottom: 10px;
}
.zone {
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
}
.email-content {
  display: flex;
  flex-direction: column;
}
