.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
    margin: 6px;
}

.bodyForControlAdaugaEchipament .TextField {
    width: 100%;
}

/* style users echipamente*/
.setup-list {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    list-style: none;
}

.setup-item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.setup-title {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    font-weight: bold;
}

.setup-description {
    margin: 10px 0;
    padding: 0;
    font-size: 1em;
}

.equipment-title {
    margin: 0;
    margin-top: 20px;
    padding: 0;
    font-size: 1.1em;
    font-weight: bold;
}

.equipment-item {
    margin: 5px 0;
    padding: 0;
    font-size: 1em;
}

.equipment-details {
    margin: 10px 0;
    padding: 0;
    font-size: 0.95em;
}