.container-title {
  text-align: center;
  padding: 0 !important;
  margin-bottom: 40px;
  font-size: 40px;
  color: #0f0d0f;
  font-weight: 600;
  line-height: 60px;
}

.ticketingCCinput>div>div>div {
  width: 100%;
}

.darkMode .ticketingCCinput>div>div>div {
  border: 1px solid #171717;
  background-color: #171717;
}


.darkMode .container-title {
  color: #fff;
}

.card {
  max-width: 550px;
  border: 0;
  width: 100%;
  margin-inline: auto;
}

.container-card {
  position: relative;
  background-clip: padding-box;
  background: #ededed;

  border-radius: 12px;
  padding: 24px;
}

.darkMode .container-card {
  background: #464646;
}

.card-title {
  font-weight: 600;
  color: black;
  letter-spacing: -0.02em;
  line-height: 18px;
  font-style: normal;
  font-size: 18px;
  padding-bottom: 8px;
}

.darkMode .card-title {
  color: white;
}

.card-description {
  line-height: 32px;
  color: #0f0d0f;
  font-size: 16px;
  max-width: 470px;
}

.card-description>span {
  font-weight: 600;
}

.darkMode .card-description {
  color: hsla(0, 9%, 98%, 0.76);
}