.uploadAvatar>.uploadAvatarBox {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #333;
    overflow: hidden;
}

.uploadAvatar>.uploadAvatarBox label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.uploadAvatar>.uploadAvatarBox label:hover .hoverSpanAvatar {
    opacity: 1;
}

.uploadAvatar>.uploadAvatarBox label img {
    width: 100%;
}

.uploadAvatar>.uploadAvatarBox label .hoverSpanAvatar {
    position: absolute;
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.4s ease-out;
}

.uploadAvatar #avatar {
    display: none;
}

.uploadAvatar>.uploadAvatarBox label .infoSpanAvatar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}