.ticketForm {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.ql-container {
  height: auto !important;
}

.MuiTabs-scroller {
  scrollbar-width: thin;
  -ms-overflow-style: none;
  overflow: auto !important;
}

.MuiTabs-scroller::-webkit-scrollbar {
  display: none;
}

.ticketsDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.clickable span {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.ticket-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 24px;
  background-color: #f7f7f7;
  width: auto;
  max-width: 900px;
}

.ticket-container p {
  margin: 5px 0;
}

.ticket-number {
  font-weight: bold;
  font-size: 20px;
}

.ticket-title {
  font-size: 16px;
}

.ticket-subject {
  font-size: 20px;
  font-weight: bold;
}

.ticketDetails {
  font-weight: bold;
}

.ticket-details-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.usersDetails {
  margin-top: 20px;
  gap: 20px;
}

.ticket-dialog .MuiPaper-rounded {
  max-width: initial !important;
}

.ticket-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.ticket-comment.reported {
  margin-left: 80px;
  margin-right: 0;
  border-radius: 12px 12px 0 12px;
}

.ticket-comment {
  margin-right: 80px;
  margin-bottom: 12px;
  border-radius: 12px 12px 12px 0;
  /* overflow: hidden; */
  border: 1px solid #ecd6c3;
}

/* .ticket-comment.reported .ticket-comment-header {
  background: rgb(0, 255, 200);
}
.ticket-comment.selected .ticket-comment-header {
  background: red;
} */
.ticket-comments {
  margin-bottom: 24px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.ticket-comment-header {
  display: flex;
  justify-content: space-between;
  background: #ecd6c3;
  align-items: center;
  padding: 0 12px;
}

.lightMode .ticket-comment-header {
  border-radius: 9px 9px 0px 0px;
}

.ticket-comment-header p {
  font-weight: bold;
}

.ticket-comment-header p > span {
  font-weight: normal;
}

.ticket-comment-header > span {
  font-weight: bold;
  cursor: pointer;
}

.ticket-body {
  padding: 6px 12px;
}

.ticket-body span {
  color: #a7a1a1;
}

.ticket-answear-acction {
  min-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ticket-answear-acction > * {
  width: 100%;
}

.ticket-answear {
  display: flex;
  gap: 20px;
}

.commentBox {
  width: 100%;
}

.commEditMode {
  display: flex;
  flex-direction: row;
}

.crbAtasamente {
  align-items: initial !important;
  flex-direction: column !important;
  gap: 6px !important;
  padding: 0 6px 0 0;
  overflow-x: hidden;
}

.crbTopUpload {
  display: flex;
  justify-content: space-between;
}

.rowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.columnDiv {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 3px dashed rgb(192, 187, 187);
  padding: 7px;
  max-height: 200px;
}

.uploadNote {
  /* border: 1px dashed rgb(66, 63, 63); */
  margin-left: auto;
  margin-right: 0;
}

.file-input {
  display: none;
}

.fileUploaded {
  position: relative;
  border: 1px solid #80cbc4;
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 6px;
}

.xButton {
  position: absolute;
  top: -2px;
  right: -5px;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c62828;
  border: none;
  cursor: pointer;
}

.viewFileButton {
  position: absolute;
  top: -2px;
  right: -5px;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(141, 163, 159);
}

.buttonFileText {
  font-size: 10px;
  color: white;
}

.confirm {
  background-color: seagreen;
}

.decline {
  background-color: darkred;
}

.dropDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.dropZone {
  border: 2px dashed #788097;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  position: relative;
}

.selectedTicket {
  background-color: #778899;
}

.ticket-dialog-control {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
}

.teamsFooter {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.dateFirmaContainer {
  max-width: 100%;
  display: grid;
  gap: 1rem;
}

@media (min-width: 600px) {
  .dateFirmaContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .dateFirmaContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.optionControlTable tr th:last-child,
.optionControlTable tr td:last-child {
  text-align: center;
}

.optionControlTable tfoot tr button,
.optionControlTable tfoot tr .MuiTextField-root,
.input-dialog-control {
  width: 100%;
}

#emoji-palette {
  bottom: 100%;
  /* Position the emoji toolbar above */
  top: auto !important;
}
